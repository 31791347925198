@import '../../../../../styles/variables';

.image-selector {
  position: relative;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  background-color: $story-default-bg-color;
  border: $border-common;

  &:hover {
    .image-selector_wrapper {
      opacity: 0.8;
    }
  }
}

.image-selector__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  z-index: 3;
  cursor: move;
}

.image-selector__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 0;
}

.image-selector__btn {
  position: absolute;
  z-index: 5;
  border: $border-common;
  background-color: $bg-color-2;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: $bg-color--focused-2;
    opacity: 1;
  }
}

.image-selector__btn--add {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.image-selector__btn--del {
  top: 0;
  right: 0;
  background-color: $bg-color-2;
  opacity: 0.8;
}
