@import './_variables';

@mixin form-control() {
  display: block;
  width: 100%;
  padding: 7px 12px;

  color: $form-control-color;
  line-height: $form-control-line-height;
  background-color: $form-control-bg-color;
  background-clip: padding-box;
  border: 1px solid $form-control-border-color;
  border-radius: $form-control-border-radius;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:hover {
    background-color: $form-control-focus-bg-color;
  }

  &:focus {
    background-color: $form-control-focus-bg-color;
    border-color: $form-control-focus-border-color;
    box-shadow: $form-control-focus-box-shadow;
    outline: 0;
  }

  &::placeholder {
    color: $form-control-placeholder-color;
    opacity: 1;
  }

  &:-moz-ui-invalid {
    box-shadow: none;
  }

  &:disabled {
    background-color: $form-control-disabled-bg-color;
    opacity: 1;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}
