@import '../../../../styles/mixins';

.upload-media__new-body {
  display: flex;
  justify-content: space-between;
}

.upload-media__new-left {
  width: 40%;
}

.upload-media__new-right {
  @include align-center-children;
  width: 55%;
}
