@mixin center-block() {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@mixin align-center-children() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pos-abs-100() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
