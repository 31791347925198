@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '_variables';
@import '_mixins';
@import '_utils';
@import '_blueprint';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

body {
  min-width: 768px;
  color: $color-text-main;
  font-family: $font-family-main;
  font-size: $font-size-main;
  font-weight: 400;
  line-height: 1.5;
}

// TODO: double check button[disabled]
// Is it okay to have it here?
a[disabled],
button[disabled] {
  opacity: 0.65;
  pointer-events: none;
}

// TODO: move to local file where it is used
.common-divider {
  height: 1px;
  margin-top: $spacer;
  margin-bottom: $spacer;
  border: 0;
  background-color: $border-common-color;
}

.inline-divider {
  width: 1px;
  border-left: 1px solid $bg-color-2;
  display: inline-block;
  margin: 2px 7px 0;
  height: 16px;
}
