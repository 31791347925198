$spacer: 15px;
$spacer-xs: $spacer / 3;
$spacer-sm: $spacer / 2;
$spacer-lg: $spacer * 2;
$spacer-xl: $spacer * 3;
$spacer-xxl: $spacer * 4;
$story-previewer-zoom-ratio: 0.65;

$color-primary: #2699fb;
$color-primary--lighten-25: lighten($color-primary, 25%);
$color-primary--lighten-35: lighten($color-primary, 35%);
$color-primary--lighten-40: lighten($color-primary, 40%);
$color-secondary: #41cc8f;
$color-secondary--lighten-35: lighten($color-secondary, 35%);
$color-secondary--lighten-45: lighten($color-secondary, 45%);
$color-error: #721c24;
$color-error-bg: #f8d7da;
$color-text-main: #000;
$light-gray: #969696;

$font-family-main: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-xl: 22px;
$font-size-l: 18px;
$font-size-main: 16px;
$font-size-m: 14px;
$font-size-s: 12px;
$font-color: #fff;

$form-control-line-height: 1.5;
$form-control-color: #2699fb; // #495057;
$form-control-bg-highlighted: yellow;
$form-control-bg-color: #fff;
$form-control-bg-clickable: #525252;
$form-control-disabled-bg-color: #e9ecef;
$form-control-border-color: #bce0fd;
$form-control-border-radius: 0;
$form-control-focus-bg-color: lighten(#bce0fd, 13%);
$form-control-focus-border-color: darken(#bce0fd, 20%);
$form-control-placeholder-color: #bce0fd;
$form-control-focus-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

$header-height: 60px;
$border-common-color: #dedede;
$border-common: 1px solid $border-common-color;

$border-highlighted: 1px solid $form-control-border-color;
$border-highlighted-radius: $form-control-border-radius;

$sidebar-width: 70px;

$story-default-bg-color: #f5f5f5;
$btn-media-width: 40px;
$btn-media-height: 40px;

$main-section-min-width: 600px;

$table-edit-row-bg-color: lighten($color-primary, 34%);

$modal-backer-color: rgba(28, 29, 29, 0.83);

$stripe-save-card-form-bg-color: #f6f9fc;

$media-control-bg-color: lighten($color-primary, 7%);
$media-control-bg-color-focus: $color-primary;

$bg-color: $form-control-bg-color;

$bg-color--focused: $color-primary--lighten-40;
$bg-color--selected: $color-primary--lighten-35;
$bg-color--selected--focused: $color-primary--lighten-35;
$bg-color--disabled: lighten($light-gray, 35%);
$bg-color--disabled--opacity: rgba(240, 240, 240, 0.82);

$border-color--selected: darken(#bce0fd, 20%);

$bg-color-2: lighten($light-gray, 20%);
$bg-color--focused-2: darken($color-primary--lighten-35, 15%);

$border-radius: 8px;

$inverted-link-color: #f5f5f5;
$inverted-link-color-focus: #fff;

// z-index
$z-index-sticky-sidebar: 99;
$z-index-sticky-header: 99;
$z-index-dialog-open: 100;
$z-index-modal-open: 101;
$z-index-notification: 102;

// modal width
$modal-xl: 1000px;
$content-width: 1100px;
