@import './variables';
@import './mixins';

.center-block {
  @include center-block;
}

.align-center-children {
  @include align-center-children;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-truncate {
  @include text-truncate;
}

.margin-top--xs {
  margin-top: $spacer-xs !important;
}

.margin-top--xl {
  margin-top: $spacer-xl !important;
}

.margin-top--lg {
  margin-top: $spacer-lg !important;
}

.margin-top {
  margin-top: $spacer !important;
}

.margin-top--sm {
  margin-top: $spacer-sm !important;
}

.margin-right {
  margin-right: $spacer !important;
}

.margin-right--sm {
  margin-right: $spacer-sm !important;
}

.margin-bottom {
  margin-bottom: $spacer !important;
}

.margin-right--lg {
  margin-right: $spacer-lg !important;
}

.margin-bottom--sm {
  margin-bottom: $spacer-sm !important;
}

.margin-bottom--xs {
  margin-bottom: $spacer-xs !important;
}

.margin-bottom--lg {
  margin-bottom: $spacer-lg !important;
}

.margin-bottom--xl {
  margin-bottom: $spacer-xl !important;
}

.margin-left {
  margin-left: $spacer !important;
}

.margin-left--lg {
  margin-left: $spacer-lg !important;
}

.margin-left--xxlg {
  margin-left: $spacer-xxl !important;
}

.margin-left--sm {
  margin-left: $spacer-sm !important;
}

.margin--lg {
  margin: $spacer-lg !important;
}

.margin--sm {
  margin: $spacer-sm !important;
}

.margin--xs {
  margin: $spacer-xs !important;
}

.padding {
  padding: $spacer !important;
}

.padding--xs {
  padding: $spacer-xs !important;
}

.padding--lg {
  padding: $spacer-lg !important;
}

.padding-top--xxl {
  padding-top: $spacer-xxl !important;
}

.padding-top--xl {
  padding-top: $spacer-xl !important;
}

.padding-top--lg {
  padding-top: $spacer-lg !important;
}

.padding-top {
  padding-top: $spacer !important;
}

.padding-top-sm {
  padding-top: $spacer-sm !important;
}

.padding-right {
  padding-right: $spacer !important;
}

.padding-right-sm {
  padding-right: $spacer-sm !important;
}

.padding-bottom--lg {
  padding-bottom: $spacer-lg !important;
}

.padding-bottom--xl {
  padding-bottom: $spacer-xl !important;
}

.padding-bottom {
  padding-bottom: $spacer !important;
}

.padding-left {
  padding-left: $spacer !important;
}

.padding-left--lg {
  padding-left: $spacer-lg !important;
}

.padding-left-right--sm {
  padding-right: $spacer-sm !important;
  padding-left: $spacer-sm !important;
}

.padding-left-right--lg {
  padding-right: $spacer-lg !important;
  padding-left: $spacer-lg !important;
}

.img-fluid {
  display: block;
  max-width: 100%;
  height: auto;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.stretch-block {
  width: 100%;
  height: 100%;
}

.pos-abs-100 {
  @include pos-abs-100;
}

.pos-relative {
  position: relative;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-cover {
  object-fit: cover;
}

.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-2-0 {
  flex: 2 0;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.border-bottom-common {
  border-bottom: $border-common !important;
}

.border-common {
  border: $border-common !important;
}

.no-borders {
  border: 0;
}

.form-inline {
  display: flex;
  align-items: flex-end;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.color-error {
  color: $color-error;
}

.color-primary {
  color: $color-primary;
}

.color-clickable {
  color: $form-control-bg-clickable;
}

.z-index-1 {
  z-index: 1;
}

.block-danger {
  color: $color-error;
  background-color: $color-error-bg;
  border-color: #f5c6cb;
}

.block-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.block-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.block-warn {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.block-group-highlighted {
  border: $border-highlighted;
  border-radius: $border-highlighted-radius;
}

.form-cell__wrapper {
  width: 200px;
}
