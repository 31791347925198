@import './_variables';

.bp3-intent-primary {
  .bp3-spinner-head {
    stroke: $color-primary !important;
  }

  .bp3-progress-meter {
    background-color: $color-primary !important;
  }
}

.bp3-button.bp3-minimal.bp3-intent-primary,
.bp3-icon.bp3-intent-primary {
  color: $color-primary !important;
}

.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: $color-primary !important;
}

.bp3-tabs {
  .bp3-tab-list {
    padding-right: $spacer-lg;
    padding-left: $spacer-lg;
    border-bottom: $border-common;
  }

  .bp3-tab-list > *:not(:last-child) {
    margin-right: 0;
  }

  .bp3-tab {
    padding-right: $spacer;
    padding-left: $spacer;
    color: $color-primary;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .bp3-tab[aria-selected='true'],
  .bp3-tab:not([aria-disabled='true']):hover {
    color: #fff;
    background-color: $color-primary;
  }

  // selected indicator
  .bp3-tab[aria-selected='true'] {
    box-shadow: none;
  }

  .bp3-tab-panel {
    padding-left: $spacer-lg;
    padding-right: $spacer-lg;
  }
}
