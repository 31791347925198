@import '../../../../styles/variables';
@import '../../../../styles/mixins';

$preview-image-height: 200px;
$selected-background-color: rgba(125, 188, 255, 0.2);
$selected-border-color: rgba(125, 188, 255, 0.95);

.media-tile {
  @include align-center-children;
  position: relative;
  width: calc(33.33333% - 15px);
  height: $preview-image-height;
  margin-right: $spacer;
  margin-bottom: $spacer;

  // for white images we need to show some background
  // so the user can actually see the picture
  & img,
  & video {
    background-color: #7a7a7a;
    z-index: 0;
  }
}

.media-tile__inner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;

  &:hover {
    background-color: $selected-background-color;

    .media-tile__btn-delete {
      display: flex;
    }
    .media-tile__user-link {
      opacity: 1;
    }
  }
}

.media-tile__inner--selected {
  background-color: $selected-background-color;
  border-color: $selected-border-color;
}

.media-tile__btn-select {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
}

.media-tile__btn-delete.bp3-button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
  display: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;

  &.bp3-minimal {
    background-color: $media-control-bg-color;

    &:hover {
      background-color: $media-control-bg-color-focus;
    }

    .bp3-icon {
      color: #fff;
    }
  }
}

.media-tile__file {
  max-height: 200px;
}

.media-tile__user-link {
  opacity: 0;
  color: $inverted-link-color;
  background-color: $media-control-bg-color;
  padding: 3px;

  &:hover {
    color: $inverted-link-color-focus;
  }
}
